<template>
    <v-card>
        <v-card-text>
            <template v-if="item">
                <p v-if="item.has_token" class="text-center">
                    You've already generated API token
                </p>
                <p class="text-center">
                    <v-btn @click="generateToken" outlined color="red">
                        Generate new token
                    </v-btn>
                </p>
                <v-alert
                        v-if="item.token"
                        color="primary"
                        border="left"
                        colored-border
                        elevation="2"
                >
                    <p class="text-center">
                        Save your api token shown below in safe place. We store only token's hash and token cannot be recovered.
                    </p>
                    <h2 class="text-center"><code>{{ item.token }}</code></h2>
                </v-alert>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "TokenForm",
        data(){
            return {
                item: null,
            }
        },
        created(){
            this.initialize();
        },
        methods: {
            initialize(){
                axios.get(this.route('master.apiSettings.getToken')).then( r => {
                    this.item = r.data;
                }).catch( err => {
                    console.log(err);
                })
            },
            generateToken(){
                axios.post(this.route('master.apiSettings.generateToken')).then( r => {
                    this.item = r.data;
                }).catch( err => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped>

</style>